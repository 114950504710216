import React, { useState } from "react";
import { AbsoluteWrapper, Container } from "@util/standard";
import { Maybe, SanityProduct } from "@graphql-types";
import styled from "styled-components";
import { BOORSOK_FONT, FONT_BODY } from "@util/constants";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller, EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

//@ts-ignore
import leftArrow from "@static/assets/snackles/left-arrow.svg";
//@ts-ignore
import rightArrow from "@static/assets/snackles/right-arrow.svg";

//@ts-ignore

import { CircleArrow } from "@util/assets";
// @ts-ignore
import { mainData } from "../../locales/dataConst";
interface Props {
  data: Maybe<Maybe<SanityProduct>[]> | undefined;
  pageIban: Maybe<string> | undefined;
}
const SnacklesSwiperContainer = styled.div`
  width: 100%;
  margin: 150px auto 135px auto;
  text-align: center;
  position: relative;
  @media (max-width: 1240px) {
    margin: 200px auto 100px;
  }

  @media (max-width: 768px) {
    margin: 150px auto 50px;
  }
  @media (max-width: 576px) {
    margin-bottom: 65px;
  }
  @media (max-width: 320px) {
    margin: 225px auto 50px;
  }
  .swiper-overlay-text {
    font-family: ${BOORSOK_FONT};
    font-size: 125px;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 84%;
    color: #e4007e;
    text-transform: uppercase;
    position: absolute;
    width: 62%;

    @media (max-width: 1880px) {
      width: 65%;
    }
    @media (max-width: 1660px) {
      font-size: 100px;
      width: 75%;
    }
    @media (max-width: 1240px) {
      font-size: 90px;
    }
    @media (max-width: 1024px) {
      width: 80%;
      font-size: 80px;
    }
    @media (max-width: 768px) {
      font-size: 72px;
      line-height: 79%;
      width: 80%;
      // transform: translate(-50%, -80%);
    }
    @media (max-width: 400px) {
      font-size: 60px;
    }
  }
  .slide-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    margin-top: 10px;

    @media (max-width: 1024px) {
      width: 80%;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .secondary-slide-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    margin-top: 10px;
    color: #e4007e;
    gap: 50px 0;
    font-family: ${BOORSOK_FONT};
    @media (max-width: 1024px) {
      width: 90%;
    }
    @media (max-width: 991px) {
      width: 100%;
    }

    .slider-title-container {
      font-family: ${BOORSOK_FONT};
      font-size: 64px;
      line-height: 64px;
    }
    .slider-text-container {
      display: flex;
      justify-content: center;
      align-item: center;
      gap: 0 80px;
      @media (max-width: 991px) {
        flex-direction: column;
        gap: 30px 0;
      }
      .slider-text-content {
        color: #e4007e;
        font-family: ${FONT_BODY};
        font-weight: 600;
        font-size: 26px;
        line-height: 1.2;
        text-transform: capitalize;
        @media (max-width: 991px) {
          font-size: 22px;
        }
      }
      p {
        font-weight: 400 !important;
      }
    }
  }

  .swiper-sub-container {
    width: 85%;
    margin: 0 auto;
    position: relative;
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .swiper-sub-container:after,
  .swiper-sub-container:before {
    content: "";
    position: absolute;
    top: 0;
    width: 60px;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 10;
    pointer-events: none;
  }

  .swiper-sub-container:after {
    right: 0px;
    background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0, #e9eaeb);
  }

  .swiper-sub-container:before {
    background-image: linear-gradient(-90deg, hsla(0, 0%, 100%, 0) 0, #e9eaeb);
    left: 0px;
  }
`;
const NavWrapper = styled(AbsoluteWrapper)`
  display: flex;
  cursor: pointer;
  z-index: 11;
  top: 50%;
  transform: translateY(-50%);
`;

function SnacklesCarousel({ data, pageIban }: Props) {
  const [controller, setController] = useState<SwiperCore>();
  const [secondaryController, setSecondaryController] = useState<SwiperCore>();

  const mainPageData = mainData[pageIban];
  const { swiperData } = mainPageData;

  if (data == null) return null;

  return (
    <>
      <SnacklesSwiperContainer>
        <div className="swiper-overlay-text">{mainPageData.sliderTitle}</div>

        {controller && (
          <div style={{ position: "relative" }}>
            <NavWrapper left="3%" onClick={() => controller?.slidePrev()}>
              <CircleArrow customArrow flip />
            </NavWrapper>
            <NavWrapper right="3%" onClick={() => controller?.slideNext()}>
              <CircleArrow customArrow />
            </NavWrapper>
            <div className="swiper-sub-container" style={{}}>
              <Swiper
                modules={[EffectCoverflow, Controller]}
                onSwiper={setSecondaryController}
                controller={{ control: controller }}
                onClick={(swiper: Swiper) => {
                  const clickedIndex = swiper.clickedIndex;
                  if (clickedIndex !== undefined) {
                    controller.slideTo(clickedIndex);
                  }
                }}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={0.8}
                spaceBetween={60}
                coverflowEffect={{
                  rotate: 0.1,
                  stretch: 0,
                  depth: 500,
                  modifier: 1,
                  slideShadows: false,
                }}
                loop={true}
                loopedSlides={swiperData.length}
                breakpoints={{
                  320: {
                    slidesPerView: 1.5,
                    spaceBetween: 100,
                  },
                  576: {
                    slidesPerView: 1.5,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1440: {
                    slidesPerView: 2.45,
                  },
                }}
              >
                {swiperData.map(item => {
                  return (
                    <SwiperSlide key={item.id}>
                      <div className="slide-content">
                        <img src={item.swiperImg} alt={item.name} />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        )}
        <Container width="100%" padding="110px 0 0 0" mobilePadding="30px 0 0">
          <Swiper
            modules={[Controller]}
            onSwiper={setController}
            controller={{ control: secondaryController }}
            loop
            slidesPerView={1}
            loopedSlides={swiperData.length}
          >
            {swiperData.map(item => {
              return (
                <SwiperSlide key={item.id}>
                  <div className="secondary-slide-container">
                    <div className="slider-title-container">{item.name}</div>
                    <div className="slider-text-container">
                      <div>
                        <span className="slider-text-content">{item.bdayTitle}</span>
                        <p className="slider-text-content">{item.bday}</p>
                      </div>
                      <div>
                        <span className="slider-text-content">{item.hobbyTitle}</span>
                        <p className="slider-text-content">{item.hobby}</p>
                      </div>
                      <div>
                        <span className="slider-text-content">{item.starSignTitle}</span>
                        <p className="slider-text-content">{item.starSign}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Container>
      </SnacklesSwiperContainer>
    </>
  );
}
export default SnacklesCarousel;
