import React, { useEffect, useRef, useState } from "react";
import { navigate } from 'gatsby';

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller } from "swiper";

import styled from "styled-components";
import { Maybe, SanityProduct } from "@graphql-types";
import { AbsoluteWrapper, Container } from "@util/standard";
import { Eurostile_BQ, colors, colorsRGB, mediaQuery } from "@util/constants";
import { CircleArrow } from "@util/assets";

//@ts-ignore
import xShot00 from "@static/assets/xshot-blastercorn.png"
//@ts-ignore
import xShotMini00 from "@static/assets/xshot-blastercorn-mini.png"
//
interface Props {
  data: Maybe<Maybe<SanityProduct>[]> | undefined | any;
}

const StyledSwiper = styled(Swiper)`
  width: 100%;
  .swiper-slide {
    height: 230px;
    background: ${colorsRGB.charcoal(0.1)};
    opacity: 0.5;
    cursor: pointer;
    ${mediaQuery.largeDown} {
      height: 150px;
    }
  }
  .swiper-slide-active {
    height: 230px;
    opacity: 1;
    background: ${colors.white};

    ${mediaQuery.largeDown} {
      height: 150px;
    }
  }
`;
const NavWrapper = styled(AbsoluteWrapper)`
  display: flex;
  filter: invert(1);
  cursor: pointer;
  z-index: 3;
  top: 50%;
  width: 100%;
  padding: 0 20px;
  position: static;
  transform: translateY(41px);
  width: 30%;
  margin: 0px auto 70px auto;
  justify-content: space-between;

  @media (max-width: 768px) {
    position: static;
    transform: translateY(65%);
    width: 30%;
    padding: 0 10px;
    margin: 0px auto 70px auto;
    justify-content: space-between;
  }
  @media (max-width: 576px) {
    padding: 0;
  }
`;

const SlideContentWrapper = styled.div`
  margin: 0 auto 0 auto;
  text-align: center;

  .image-text-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5vw;
    height: 750px;
    @media only screen and (max-width: 991px) {
      height: 700px;
    }
    @media only screen and (max-width: 768px) {
      height: 600px;
    }
    @media only screen and (max-width: 576px) {
      height: 400px;
    }
  }
  .slider-background-text {
    font-family: "Formula Condensed Bold", sans-serif;
    font-weight: 700;
    font-size: 500px;
    line-height: 110%;
    color: #D9D9D9;
    text-shadow: -1px -1px 0 #D9D9D9, 1px -1px 0 #D9D9D9, -1px 1px 0 #D9D9D9, 1px 1px 0 #D9D9D9;
    color: white;
    letter-spacing: 0.035em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    @media only screen and (max-width: 1520px) {
      font-size: 450px;
    }
    @media only screen and (max-width: 1420px) {
      font-size: 420px;
    }
    @media only screen and (max-width: 1350px) {
      font-size: 390px;
    }
    @media only screen and (max-width: 1250px) {
      font-size: 360px;
    }
    @media only screen and (max-width: 1150px) {
      font-size: 330px;
    }
    @media only screen and (max-width: 1050px) {
      font-size: 300px;

    }
    @media only screen and (max-width: 970px) {
      font-size: 280px;
    }
    @media only screen and (max-width: 840px) {
      font-size: 230px;
    }
    @media only screen and (max-width: 680px) {
      font-size: 185px;
     }
    @media only screen and (max-width: 576px) {
      font-size: 150px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 130px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 110px;
    }
    @media only screen and (max-width: 350px) {
      font-size: 100px;
    }
  }
  .slider-content-container {
    display: flex;
    margin: 60px 50px;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      justify-content: end;
      margin: 40px 50px;
    }
    @media only screen and (max-width: 768px) {
      margin: 30px 25px;
    }
 
  .slider-content-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      align-items: flex-start;
    }
    .slider-content-header {
      font-family:${Eurostile_BQ};
      font-size: 72px;
      font-weight: 500;
      line-height: 65px;
      letter-spacing: -0.05em;
      text-align: left;
      margin-bottom: 60px;
      @media only screen and (max-width: 1280px) {
        font-size: 60px;
        margin-bottom: 0;
        line-height: 1;
      }
      @media only screen and (max-width: 576px) {
        font-size: 48px;
      }
      @media only screen and (max-width: 400px) {
        font-size: 36px;
      }
    }
    .slider-content-points {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px 15%;
      text-align: left;
      list-style: disc;
      @media only screen and (max-width: 1024px) {
        margin: 30px 0;
        width: 100%;
      }
      @media only screen and (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px 0;
      }
      li {
        font-size: 16px;
      }
    }
  }
  .slider-content-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
    .slider-content {
      text-align: left;
      font-size: 16px;
    }
    .slider-content-button {
      background-color: ${colors.white};
      font-size: 18px;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: -0.05em;
      text-align: center;
      margin-top: 46px;
      @media only screen and (max-width: 576px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
    .background-btn {
      background-color: ${colors.black};
      border-radius: 68px;
      color: ${colors.white};
      cursor: pointer;
      padding: 12px 28px;
      margin-right: 32px;
      @media only screen and (max-width: 576px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    .border-btn {
      border: 1px solid ${colors.black};
      border-radius: 100px;
      color: ${colors.black};
      cursor: pointer;
      background-color: ${colors.white};
      padding: 12px 28px;
    }
  }
`;
const SecondarySlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SliderButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 50px;
  @media (max-width: 567px) {
    right: 30px;
  }
`;

function BlastercornSlider({ data }: Props) {

  const slideData = [
    {
      id: 0,
      image: xShot00,
      smallImage: xShotMini00,
      backgroundText: 'XSHOT',
      sliderText: 'XSHOT Blastercorn',
      urlText: 'blastercorn',
      buttonLink: '/where-to-buy',
      content: `Are you game to tame the Blastercorn? Unleash 16 foam darts in seconds with the Blastercorns simple pull to prime reload. And watch its wings flap as you unleash the power of the Blastercorn on your enemies!`,
      contentPoints: ['Single Dart Capacity', 'Air Pocket Dart Technology', '27m / 90 Feet Firing Distance', 'Flapping Wings'],
    },
  ];
  const [controller, setController] = useState<SwiperCore>();
  const [secondaryController, setSecondaryController] = useState<SwiperCore>();
  const [initialSlideIndex, setInitialSlideIndex] = useState(0);

  const slideChanged = useRef(false);
  const swiperRef = useRef(null);
  useEffect(() => {
    const urlFragment = window.location.hash.substring(1);
    const slideIndex = slideData.findIndex(slide => slide.urlText === urlFragment);

    if (slideIndex !== -1) {
      setInitialSlideIndex(slideIndex);
    }
  }, []);

  const handleSlideChange = (swiper: any) => {
    if (!slideChanged.current) {
      slideChanged.current = true;
      return;
    }

    const activeSlide = slideData[swiper.realIndex];
    const urlText = activeSlide.urlText;
    navigate(`/brands/x-shot/blastercorn#${urlText}`);
  };
  const breakpoints = {
    576: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    992: {
      slidesPerView: 4,
    },
  };
  const handleButtonClick = () => {
    const videoElement = document.getElementById('blastercorn-video');
    videoElement?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Container flexDirection="column" width="100%">
        <Container width="100%">
          {controller && (
            <StyledSwiper
              modules={[Controller]}
              onSwiper={setSecondaryController}
              controller={{ control: controller }}
              slidesPerView={2}
              loop
              spaceBetween={5}
              loopedSlides={slideData.length}
              initialSlide={initialSlideIndex}
              onClick={e => controller.slideTo(e.clickedIndex)}
              breakpoints={breakpoints}
            >
              {slideData.map((product, index) => {
                return (
                  <SwiperSlide key={`swiper_2_${product.id}${index}`}>
                    <SecondarySlideWrapper>
                      {<img src={product.smallImage} />}
                    </SecondarySlideWrapper>
                  </SwiperSlide>
                );
              })}
            </StyledSwiper>
          )}
        </Container>
        <Container position="relative">
          <Container>
            <SliderButtonWrapper>
              <NavWrapper onClick={() => controller?.slidePrev()}>
                <CircleArrow />
              </NavWrapper>
              <NavWrapper onClick={() => controller?.slideNext()}>
                <CircleArrow flip />
              </NavWrapper>
            </SliderButtonWrapper>
          </Container>
          <Swiper
            //@ts-ignore
            ref={swiperRef}
            modules={[Controller]}
            onSwiper={setController}
            controller={{ control: secondaryController }}
            loop
            autoplay
            slidesPerView={1}
            loopedSlides={slideData.length}
            initialSlide={initialSlideIndex}
            onSlideChange={handleSlideChange}
          >
            {slideData.map((product) => {
              return (
                <SwiperSlide key={`swiper_1_${product.id}`}>
                  <SlideContentWrapper>
                    <div className="image-text-wrapper">
                      <div className="slider-background-text">{product.backgroundText}</div>
                      <img src={product.image} alt={product.backgroundText} />
                    </div>
                    <div className="slider-content-container">
                      <div className="slider-content-left">
                        <div className="slider-content-header">
                          {product.sliderText}
                        </div>
                        <div className="slider-content-points">
                          {product.contentPoints.map((point, index) => (
                            <li key={index}>{point}</li>
                          ))}
                        </div>
                      </div>
                      <div className="slider-content-right">
                        <div className="slider-content">
                          {product.content}
                        </div>
                        <div className="slider-content-button">
                          <a href={product.buttonLink}>
                            <button className="border-btn">
                              Where to buy </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </SlideContentWrapper>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Container>
      </Container >
    </>
  )
}

export default BlastercornSlider;